import BadgesUploadPage from '../pages/BadgesUploadPage'
import Certificates from '../pages/Certificates'
import PsyPaymentStats from '../pages/PsyPaymentStats'
import PsyPaymentStatsByCurrency from '../pages/PsyPaymentStatsByCurrency'
import PsychologistsUploadPage from '../pages/PsychologistsUploadPage'
import RatesUploadPage from '../pages/RatesUploadPage'
import React, { Suspense, lazy, memo } from 'react'
import { AppLayout } from '../templates/Layout'
import { AuthProvider } from './AuthProvider'
import { Login } from '../pages/Login'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Spin } from 'antd'
import type { FC, PropsWithChildren } from 'react'

const Company = lazy(() => import('../pages/Company'))
const Sessions = lazy(() => import('../pages/Sessions'))
const ClientTransactions = lazy(() => import('../pages/ClientTransactions'))
const Promocodes = lazy(() => import('../pages/Promocodes'))
const Relationships = lazy(() => import('../pages/Relationships'))
const Badges = lazy(() => import('../pages/Badges'))
const Clients = lazy(() => import('../pages/Clients'))
const PromocodeUploadPage = lazy(() => import('../pages/PromocodeUploadPage'))
const Uploads = lazy(() => import('../pages/Uploads'))
const B2BStats = lazy(() => import('../pages/B2BStats'))
const Companies = lazy(() => import('../pages/Companies'))
const PsychologistSchedule = lazy(() => import('../pages/Schedule'))
const PsychologistProfile = lazy(() => import('../pages/Profile'))
const PsychologistSearch = lazy(() => import('../pages/Search'))
const PsychologistSearchProfile = lazy(() => import('../pages/SearchProfile'))
const CertificateUploadPage = lazy(
  () => import('../pages/CertificateUploadPage')
)

const OnSuspense: FC<{
  component: ReturnType<React.LazyExoticComponent<() => React.JSX.Element>>
}> = ({ component }) => <Suspense fallback={<Spin />}>{component}</Suspense>

// eslint-disable-next-line max-lines-per-function
const RouterConfig: FC<PropsWithChildren> = memo(() => (
  <Routes>
    <Route element={<AuthProvider />}>
      <Route element={<AppLayout />}>
        <Route element={<></>} path="/" />
        <Route
          element={<OnSuspense component={<Sessions />} />}
          path="psychologists/sessions"
        />
        <Route
          element={<OnSuspense component={<Relationships />} />}
          path="psychologists/relationships"
        />
        <Route
          element={<OnSuspense component={<Badges />} />}
          path="psychologists/badges"
        />
        <Route
          element={<OnSuspense component={<Certificates />} />}
          path="certificates"
        />
        <Route
          element={<OnSuspense component={<Promocodes />} />}
          path="promocodes"
        />
        <Route
          element={<OnSuspense component={<ClientTransactions />} />}
          path="clients/transactions"
        />
        <Route
          element={<OnSuspense component={<Clients />} />}
          path="clients/list"
        />
        <Route
          element={<OnSuspense component={<Uploads />} />}
          path="uploads/list"
        />
        <Route
          element={<OnSuspense component={<PromocodeUploadPage />} />}
          path="uploads/promocodes"
        />
        <Route
          element={<OnSuspense component={<CertificateUploadPage />} />}
          path="uploads/certificates"
        />
        <Route
          element={<OnSuspense component={<BadgesUploadPage />} />}
          path="uploads/badges"
        />
        <Route
          element={<OnSuspense component={<PsychologistsUploadPage />} />}
          path="uploads/psychologists"
        />
        <Route
          element={<OnSuspense component={<RatesUploadPage />} />}
          path="uploads/rates"
        />
        RatesUploadPage
        <Route
          element={<OnSuspense component={<B2BStats />} />}
          path="b2b/sessions-stats"
        />
        <Route
          element={<OnSuspense component={<PsyPaymentStats />} />}
          path="b2b/sessions-stats-psy"
        />
        <Route
          element={<OnSuspense component={<PsyPaymentStatsByCurrency />} />}
          path="b2b/sessions-stats-psy-currency"
        />
        <Route
          element={<OnSuspense component={<Companies />} />}
          path="companies"
        />
        <Route
          element={<OnSuspense component={<Company />} />}
          path="companies/:id"
        />
        <Route
          element={<OnSuspense component={<PsychologistSchedule />} />}
          path="psychologists/schedule"
        />
        <Route
          element={<OnSuspense component={<PsychologistProfile />} />}
          path="psychologists/profile"
        />
        <Route
          element={<OnSuspense component={<PsychologistSearch />} />}
          path="psychologists/search"
        />
        <Route
          element={<OnSuspense component={<PsychologistSearchProfile />} />}
          path="psychologists/search/:id"
        />
        <Route element={<Navigate replace to="/" />} path="*" />
      </Route>
      <Route element={<Login />} path="/login" />
    </Route>
  </Routes>
))

export default RouterConfig
