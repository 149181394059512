import { nonNullishParams } from '../../../api/utils'
import { tableName } from '.'
import { useGetDateRangeForSend } from '../../lib/formatters'
import { useSessionsDownloadByCurrencyQueryRefetchOnly } from '../../../api/sessions'
import { useTableFiltersByName } from '../../organisms/SearchPanel/store'
import type dayjs from 'dayjs'
import type { SessionDownloadStatsParams } from '../../../api/sessions'

type B2BFiltersParams = Omit<SessionDownloadStatsParams, 'from' | 'to'> & {
  dates?: [dayjs.Dayjs, dayjs.Dayjs]
}

const useFiltersParams = (filters?: B2BFiltersParams) => {
  const [from, to] = useGetDateRangeForSend(filters?.dates)

  return nonNullishParams<SessionDownloadStatsParams>({
    from,
    to
  })
}

export const useSessionStatsByCurrencyPageQueries = () => {
  const filters = useTableFiltersByName<SessionDownloadStatsParams>(tableName)
  const filtersParams = useFiltersParams(filters)

  const { data, isLoading, refetch } =
    useSessionsDownloadByCurrencyQueryRefetchOnly(filtersParams)

  return {
    data,
    isLoading,
    refetch
  }
}
