import '../../atoms/dayjsRuLocale'
import React, { useCallback, useEffect, useState } from 'react'
import css from './index.module.scss'
import { Alert, Button, Drawer, Space } from 'antd'
import { BellOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { matchPath, useLocation } from 'react-router-dom'
import { useNotificationHistory } from '../../store/notifications'

const goBack = () => window.history.back()

const titleRoutesMap = [
  ['/psychologists/sessions', 'Психологи: Cессии'],
  ['/psychologists/relationships', 'Психологи: Отношения'],
  ['/psychologists/schedule', 'Психологи: Расписание'],
  ['/psychologists/profile', 'Психологи: Профили'],
  ['/psychologists/search', 'Психологи: Поиск'],
  ['/psychologists/search/:id', 'Психологи: Профиль'],
  ['/psychologists/badges', 'Психологи: Бейджи'],
  ['/clients/transactions', 'Клиенты: Транзакции'],
  ['/companies', 'Компании'],
  ['/companies/:id', 'Компания'],
  ['/certificates', 'Сертификаты'],
  ['/promocodes', 'Промокоды'],
  ['/uploads/list', 'Загрузки: Список'],
  ['/uploads/promocodes', 'Загрузки: Промокоды'],
  ['/uploads/certificates', 'Загрузки: Сертификаты'],
  ['/uploads/badges', 'Загрузки: Бейджи'],
  ['/uploads/psychologists', 'Загрузки: Психологи'],
  ['/uploads/rates', 'Загрузки: Цены'],
  ['/clients/list', 'Список'],
  ['/b2b/sessions-stats', 'По компании'],
  ['/b2b/sessions-stats-psy', 'По психологам'],
  ['/b2b/sessions-stats-psy-currency', 'По валютам']
]

const titles: Record<string, string> = titleRoutesMap.reduce<
  Record<string, string>
>((acc, [key, value]) => ({ ...acc, [`${key}/`]: value, [key]: value }), {})

const HistoryDrawer = ({
  open,
  toggleDrawer
}: {
  open: boolean
  toggleDrawer: () => void
}) => {
  const history = useNotificationHistory()

  return (
    <Drawer
      onClose={toggleDrawer}
      open={open}
      placement="right"
      title="История уведомлений"
    >
      <Space className={css.space}>
        {history
          .sort((curr, next) => (curr.date < next.date ? 1 : -1))
          .map(args => (
            <Alert
              key={args.key}
              showIcon
              description={[args.message, args.description].join(' ')}
              message={args.date.format('DD-MM-YYYY HH:mm:ss')}
              type={args.type}
            />
          ))}
      </Space>
    </Drawer>
  )
}

const getMatchingTitle = (path: string) => {
  const matchingTitle = Object.keys(titles).find(t => matchPath(t, path))

  if (!matchingTitle) {
    return 'CRM'
  }

  return titles[matchingTitle]
}

const DEFAULT_TITLE = 'CRM'

export const Header = () => {
  const { pathname } = useLocation()
  const [isOpen, setIsOpen] = useState(false)
  const [title, setTitle] = useState(DEFAULT_TITLE)

  const toggleDrawer = useCallback(() => setIsOpen(!isOpen), [isOpen])

  useEffect(() => {
    if (typeof document.title !== 'undefined') {
      document.title = getMatchingTitle(pathname)
      setTitle(document.title)
    }

    return () => {
      setTitle(DEFAULT_TITLE)
      document.title = DEFAULT_TITLE
    }
  }, [pathname])

  return (
    <PageHeader
      className={css.header}
      extra={[
        <Button
          key="history"
          icon={<BellOutlined />}
          onClick={toggleDrawer}
          type="primary"
        />
      ]}
      onBack={goBack}
      title={title}
    >
      <HistoryDrawer open={isOpen} toggleDrawer={toggleDrawer} />
    </PageHeader>
  )
}
